// Colors
$text-color: #4E4E4E;
$link-color: #713EE6;
$link-hover-color: #9064FF;

$color-status-pending: #F47314;
$color-status-awaiting-transcription: #F7A31D;
$color-status-awaiting-summary-sent: #33B7CE;
$color-status-summary-sent: #1289EF;
$color-status-completed: #26B769;

// Buttons
$btn-bg-color: #6A1F73;
$btn-bg-hover: #8C64FF;
$btn-secondary-bg-color: #18A0A5;
$btn-secondary-bg-hover: #20B2B7;
$btn-add-bg-color: #0FA756;
$btn-add-bg-hover: #0A8B47;
$btn-delete-bg-color: #D41D16;
$btn-delete-bg-hover: #EC3F38;

// Forms
$input-color: #5A5A5A;
$input-color-disabled: #999999;

$input-bg-color: #F4F4F4;
$input-bg-focus: #EBEBEB;
$input-bg-disabled: $input-bg-color;

