.regularScheduleWrapper {
  width: 90%;
  margin: -75px 0 75px 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;
  opacity: 1;
  padding: 30px;
}

.regularScheduleWrapper thead tr th {
  padding: 10px;
  border-top: none;
  border-bottom: 1px solid #F3F2F4 !important;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #434343;
  align-content: center;
}

.regularScheduleWrapper thead tr th svg {
  color: #707070;
}

.regularScheduleWrapper tbody tr td {
  border-top: none !important;
  border-bottom: 1px solid #F3F2F4 !important;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  letter-spacing: 0;
  color: #4E4E4E;
  padding: 10px;
}

.regularScheduleWrapper .dataTables_wrapper  .dataTables_filter {
  float: none;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 9px;
  letter-spacing: 0;
  color: #434343;
}

.regularScheduleWrapper .dataTables_filter label {
  width: 100%;
}

.regularScheduleWrapper .dataTables_filter input {
  width: 75%;
  padding: 5px 5px;
  background-image: url("../../img/icons/search-solid.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-color: #F4F4F4;
  border: none;
}

.regularScheduleWrapper .dataTables_length {
  float: none;
  text-align: right;
  font-weight: 600;
  font-size: 16px;
  line-height: 9px;
  letter-spacing: 0;
  color: #434343;
}

.regularScheduleWrapper .dataTables_length select {
  border: none;
  background: #FFFFFF;
  color: #713EE6;
  border-bottom: 1px solid #713EE6;
}

.offHoursScheduleWrapper {
  width: 90%;
  margin: 0 0 75px 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;
  opacity: 1;
  padding: 30px;
}

.offHoursScheduleWrapper thead tr th {
  padding: 10px;
  border-top: none;
  border-bottom: 1px solid #F3F2F4 !important;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #434343;
  align-content: center;
}

.offHoursScheduleWrapper thead tr th svg {
  color: #707070;
}

.offHoursScheduleWrapper tbody tr td {
  border-top: none !important;
  border-bottom: 1px solid #F3F2F4 !important;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  letter-spacing: 0;
  color: #4E4E4E;
  padding: 10px;
}

.offHoursScheduleWrapper .dataTables_filter {
  float: none;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 9px;
  letter-spacing: 0;
  color: #434343;
}

.offHoursScheduleWrapper .dataTables_filter label {
  width: 100%;
}

.offHoursScheduleWrapper .dataTables_filter input {
  width: 75%;
  padding: 5px 5px;
  background-image: url("../../img/icons/search-solid.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-color: #F4F4F4;
  border: none;
}

.offHoursScheduleWrapper .dataTables_length {
  float: none;
  text-align: right;
  font-weight: 600;
  font-size: 16px;
  line-height: 9px;
  letter-spacing: 0;
  color: #434343;
}

.offHoursScheduleWrapper .dataTables_length select {
  border: none;
  background: #FFFFFF;
  color: #713EE6;
  border-bottom: 1px solid #713EE6;
}

.regularScheduleFormWrapper {
  width: 90%;
  margin: -75px 0 75px 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;
  opacity: 1;
  padding: 30px;
}

.regularScheduleFormWrapper label,
.regularScheduleFormWrapper legend {
  font-weight: 600;
  font-size: 17px;
  line-height: 9px;
  color: #4E4E4E;
}

#form_startDate_date_day,
#form_startDate_date_year {
  margin: 0 5px !important;
}