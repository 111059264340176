.new-call,
.new-call:active,
.new-call:focus,
.new-call:visited {
  padding: 5px 10px;
  color: #FFFFFF;
  background: #FFFFFF1C 0% 0% no-repeat padding-box;
  border: 1px solid #FFFFFF46;
  border-radius: 18px;
  opacity: 1;
  font-weight: 600;
  font-size: 15px;
  line-height: 9px;
  letter-spacing: 0;
  text-decoration: none;
}

.new-call:hover {
  color: #FFFFFF;
  text-decoration: none;
}

.callsTableWrapper {
  width: 90%;
  margin: -75px 0 75px 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;
  opacity: 1;
  padding: 30px;
}

.callFormContentWrapper {
  width: 90%;
  margin: -75px 0 75px 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;
  opacity: 1;
  padding: 0 30px 30px 30px;
  font-weight: 600;
  font-size: 17px;
  line-height: 9px;
  color: #4E4E4E;
}

.callFormHeader {
  width: 100%;
  padding: 5px 0 10px 0;
  margin: 15px 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  letter-spacing: 0;
  color: #313131;
  border-bottom: 1px solid #E2E2E2;
}

.callFormContentWrapper input[type=text],
.callFormContentWrapper input[type=text]:focus,
.callFormContentWrapper input[type=text]:active,
.callFormContentWrapper input[type=text]:visited,
.callFormContentWrapper select,
.callFormContentWrapper select:focus,
.callFormContentWrapper select:active,
.callFormContentWrapper select:visited,
.callFormContentWrapper textarea,
.callFormContentWrapper textarea:focus,
.callFormContentWrapper textarea:active,
.callFormContentWrapper textarea:visited {
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  color: #5A5A5A;
  padding-left: 5px;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.callFormContentWrapper input::placeholder {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  color: #999999;
}

.callFormContentWrapper legend {
  padding: 0;
  margin-bottom: 0.5em;
  line-height: 9px;
}

.callFormSummariesWrapper {
  background: #F4F4F4;
  border-radius: 7px;
  padding: 25px;
  margin-top: .5rem;
  margin-left: auto;
  width: 295px;
  font-size: 17px;
  font-weight: 600;
}

.callSummary {
  position: relative;
  padding-left: 1.5em;

  .callSummaryIcon {
    position: absolute;
    top: -3px;
    left: 0;

    ion-icon {
      width: 19px;
    }
  }

  .callSummaryDetails {
    font-size: 14px;
    color: #888888;
  }
}

#create_call_patient_dob_day,
#create_call_call_datetime_date_day,
#create_call_connected_datetime_date_day,
#update_call_patient_dob_day,
#update_call_call_datetime_date_day,
#update_call_connected_datetime_date_day,
#update_call_transcription_sent_date_day {
  margin: 0 5px;
}

#create_call_call_datetime_time_hour,
#create_call_connected_datetime_time_hour,
#update_call_call_datetime_time_hour,
#update_call_connected_datetime_time_hour,
#update_call_transcription_sent_time_hour {
  margin: 0 0 0 5px;
}

.attachments-container {
  position: relative;
}

.attachment-container {

}

.attachment-container .delete-attachment {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #DB230B;
  cursor: pointer;
}

.attachment-container .delete-attachment:hover {
  color: #B92411;
}

.attachment-container a {
  width: 100%;
  height: auto;
}

.attachment-container img {
  width: 100%;
  height: auto;
}

.filelist-icon {
  height: 28px;
  width: 28px;
}

.attachment-filelist .delete-attachment {
  color: #DB230B;
  font-weight: 600;
  cursor: pointer;
}

.attachment-filelist .delete-attachment:hover {
  color: #B92411;
}