.notifications .dropdown-bubble .toggle {
  padding-right: 1.8em;
  margin-right: -1.8em;
}

.notifications .dropdown-bubble .dropdown-menu {
  width: 330px;
  margin-top: 5px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 25px #00000038;
  border-radius: 7px;
  border: none;
  opacity: 1;
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  letter-spacing: 0;
  padding: 0;
}

.notifications .dropdown-bubble .dropdown-menu a,
.notifications .dropdown-bubble .dropdown-menu a:active,
.notifications .dropdown-bubble .dropdown-menu a:visited,
.notifications .dropdown-bubble .dropdown-menu a:focus {
  color: #4E4E4E;
  text-decoration: none;
}

.notifications .dropdown-bubble .dropdown-menu a:hover {
  color: #383838;
}

.notifications .dropdown-bubble .dropdown-menu:before,
.notifications .dropdown-bubble .dropdown-menu:after{
  content: ' ';
  display: block;
  border-style: solid;
  border-width: 0 .75em 1em .75em;
  border-color: transparent;
  position: absolute;
  right: 1.78em;
}

.notifications .dropdown-bubble .dropdown-menu:after {
  top: -.4em;
  border-bottom-color: #fff;
}

.notification-dropdown-title {
  text-align: left;
  font-weight: 500;
  font-size: 20.5px;
  line-height: 1.4;
  letter-spacing: 0;
  color: #4E4E4E;
  opacity: 1;
  padding: 13px 20px;
}

.notification-dropdown-footer {
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0;
  color: #713EE6 !important;
  //padding: 10px;
  border-top: 1px solid #E2E2E2;
}

.notification-dropdown-footer a {
  display: block;
  padding: 10px;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0;
  color: #713EE6 !important;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;

  &:hover {
    background: #F0EFF2;
  }
}

.dashboard-notification {
  width: 100%;
  padding: 15px;
  margin: 0;
  border-top: 1px solid #E2E2E2;
}

.notification-critical {
  border-left: 5px solid #DB230B;
}

.notification-important {
  border-left: 5px solid #F08505;
}

.notification-informational {
  border-left: 5px solid #18A0A5;
}

.notification-critical .dashboard-notification-title {
  color: #DB230B !important;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0;
  padding-bottom: 5px;
}

.notification-important .dashboard-notification-title {
  color: #F08505 !important;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0;
  padding-bottom: 5px;
}

.notification-informational .dashboard-notification-title {
  color: #18A0A5 !important;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0;
  padding-bottom: 5px;
}

.dashboard-notification-message {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #3E3E3E;
}

.noNotificationWrapper {
  width: 100%;
  min-height: 300px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;
  opacity: 1;
  padding: 35px;
}

.notificationsWrapper {
  width: 90%;
  margin: -55px 0 75px 50px;
}

.notificationWrapper {
  position: relative;
  width: 82%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;
  padding: 25px;
  margin-bottom: 30px;
}

.notificationWrapper.notification-critical {
  border-left: 7px solid #DB230B;
}

.notificationWrapper.notification-important {
  border-left: 7px solid #F08505;
}

.notificationWrapper.notification-informational {
  border-left: 7px solid #18A0A5;
}

.notificationWrapper .notificationStatus {
  position: absolute;
  top: 18px;
  right: 18px;
  font-size: 14.5px;
  color: #7C7C7C;

  ion-icon {
    vertical-align: middle;
    margin-right: 2px;
    margin-bottom: 1px;
  }
}

.notificationWrapper .notificationIcon {
  max-width: 50px;
  padding-right: 20px;

  svg,
  .svg-inline--fa {
    width: 26px;
  }

  &-critical {
    color: #DB230B;
  }

  &-important {
    color: #F08505;
  }

  &-informational {
    color: #129CB4;
  }
}

.notificationWrapper .notificationTitle {
  font-weight: 600;
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: .25em;
}

.notificationWrapper .notificationTitle-critical {
  color: #DB230B;
}

.notificationWrapper .notificationTitle-important {
  color: #F08505;
}

.notificationWrapper .notificationTitle-informational {
  color: #129CB4;
}

.notificationWrapper .notificationBody {
  max-width: 85%;
  font-weight: 500;
  font-size: 16px;
  color: #4F4F4F;
}

.notificationWrapper .notificationSubtext {
  margin-top: .75em;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #808080;
}

.notificationWrapper .notificationActionButton {
  position: absolute;
  bottom: 16px;
  right: 16px;
}
