.usersWrapper {
  width: 90%;
  margin: -75px 0 75px 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;
  opacity: 1;
  padding: 30px;
}

.usersFormWrapper {

}
