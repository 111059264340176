/* Text */
html, body {
  background: #F3F2F4 0% 0% no-repeat padding-box;
  font-family: "Slate W01", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  color: $text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, a, div, button {
  font-family: "Slate W01", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

a {
  color: $link-color;
  font-weight: 600;

  &:hover {
    color: $link-hover-color;
    text-decoration: none;
  }

  &:focus {
    outline: 2px solid #d0c3f5;
  }
}

h2 {
  font-size: 27px;
  font-weight: 500;
  margin: 0 0 .95em 0;
  color: #313131;
}

h3 {
  font-size: 23.5px;
  font-weight: 600;
  margin: 0 0 .75em 0;
  color: #313131;
}

/* Alerts */
.alert {
  margin-bottom: 0;
}

/* Datatables Overrides */
table.dataTable.no-footer {
  border-bottom: none;
}

.dataTables_wrapper .dataTables_paginate {
  float: none !important;
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0;
  color: #A2A2A2;
}

.dataTables_wrapper .dataTables_paginate .paginate_button,
.dataTables_wrapper .dataTables_paginate .paginate_button:active,
.dataTables_wrapper .dataTables_paginate .paginate_button:focus,
.dataTables_wrapper .dataTables_paginate .paginate_button:visited {
  background: none;
  border: none;
  box-shadow: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #A2A2A2;
  letter-spacing: 6.68px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: none;
  border: none;
  box-shadow: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #A2A2A2 !important;
  letter-spacing: 6.68px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:active,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:focus,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:visited,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: none;
  border: none;
  box-shadow: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #7443CE !important;
  letter-spacing: 6.86px;
}

.dataTables_wrapper .dataTables_info {
  float: none !important;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: #969696;
}


.status-circle {
  height: 10px;
  width: 10px;
  margin-right: 3px;
  border-radius: 50%;
  display: inline-block;

  &.pending {
    background-color: $color-status-pending;
  }

  &.awaiting-transcription {
    background-color: $color-status-awaiting-transcription;
  }

  &.awaiting-summary-sent {
    background-color: $color-status-awaiting-summary-sent;
  }

  &.summary-sent {
    background-color: $color-status-summary-sent;
  }

  &.completed {
    background-color: $color-status-completed;
  }
}
