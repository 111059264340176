table,
table.dataTable {
  margin: 1rem 0;

  thead tr th {
    align-content: center;
    padding: 10px;
    border-bottom: 2px solid #eaeaea;
    border-top: none;
    font-weight: 600;
    font-size: 17px;
    color: #434343;
  }

  thead .sorting,
  thead .sorting_asc,
  thead .sorting_desc {
    background: none;
    white-space: nowrap;

    svg {
      color: #707070;
      opacity: 0.5;
      margin-left: 7px;
      font-size: 14px;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  tbody tr td {
    padding: 10px;
    height: 30px;
    border-top: none;
    border-bottom: 1px solid #eaeaea;
    font-size: 17px;
    font-weight: 500;
    vertical-align: middle;
    min-width: 80px;
  }
}

.dataTables_wrapper {
  .dataTables_filter {
    float: none;
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    line-height: 9px;
    letter-spacing: 0;
    color: #434343;

    label {
      width: 100%;
    }

    input {
      width: 75%;
      padding: 5px 5px;
      background-image: url("../../img/icons/search-solid.svg");
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-color: #F4F4F4;
      border: none;
    }
  }

  .dataTables_length {
    float: none;
    text-align: right;
    font-weight: 600;
    font-size: 16px;
    line-height: 9px;
    letter-spacing: 0;
    color: #434343;

    select {
      border: none;
      background: #FFFFFF;
      color: #713EE6;
      border-bottom: 1px solid #713EE6;
    }
  }
}
