.sideNav {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000012;
  opacity: 1;
  z-index: 2;
}

.navBarTopBrand {
  text-align: center;
  padding: 10px;
  margin: 30px 0 30px 0;
}

.navBarBottomBrand {
  position: absolute;
  left: 35px;
  bottom: 25px;
  width: 65%;
  text-align: center;
  padding: 10px;
}

.sideNav ul {
  list-style: none;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.sideNav li:hover {
  background-color: #F0EFF2;
}

#nav .nav-link {
  margin: 0 auto;
  padding: .55em 2.95em;
  font-size: 16.75px;
  font-weight: 500;
  line-height: 1.3;
  color: #5A5A5A;

  ion-icon,
  .svg-inline--fa {
    margin-right: 8px;
    font-size: 21px;
    vertical-align: middle;
    margin-top: -4px;
  }

  .svg-inline--fa {
    width: 20px;
  }
}

.navBar .dropdown>.dropdown-menu {
  transition: 0.3s all ease-in-out;
}

.navBar .dropdown:hover>.dropdown-menu {
  display: block;
}

.navBar .dropdown>.dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}

.navBar .administration-dropdown-menu {
  top: 0 !important;
  left: 100% !important;
  right: 0 !important;
  transform: none !important;
  will-change: none !important;
  float: none !important;
  min-width: 230px !important;
  width: 100%;
  padding: 0;
  margin: 0;
  box-shadow: 0px 3px 25px #00000038;
  border: none;
  border-radius: 0;
}

.navBar .administration-dropdown-menu .administration-dropdown-item {
  display: inline-block;
  color: #5A5A5A;
  font-weight: 500;
  font-size: 16.75px;
  padding: 0.5rem 1rem;
  margin: 0 auto;
  text-decoration: none;
  width: 100%;
}

.navBar .administration-dropdown-menu .administration-dropdown-item:hover {
  text-decoration: none;
  background-color: #F0EFF2;
}
