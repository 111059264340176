.callHistoryRecordsWrapper {
  width: 90%;
  margin: -75px 0 75px 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;
  opacity: 1;
  padding: 30px;
}

.callHistoryRecordsTableWrapper table thead tr th {
  padding: 10px;
  border-top: none;
  border-bottom: 1px solid #F3F2F4 !important;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #434343;
  align-content: center;
}

.callHistoryRecordsTableWrapper table thead tr th svg {
  color: #707070;
}

.callHistoryRecordsTableWrapper table tbody tr td {
  border-top: none !important;
  border-bottom: 1px solid #F3F2F4 !important;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  letter-spacing: 0;
  color: #4E4E4E;
  padding: 10px;
}

.callHistoryRecordsTableWrapper .dataTables_filter {
  float: none;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 9px;
  letter-spacing: 0;
  color: #434343;
}

.callHistoryRecordsTableWrapper .dataTables_filter label {
  width: 100%;
}

.callHistoryRecordsTableWrapper .dataTables_filter input {
  width: 75%;
  padding: 5px 5px;
  background-image: url("../../img/icons/search-solid.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-color: #F4F4F4;
  border: none;
}

.callHistoryRecordsTableWrapper .dataTables_length {
  float: none;
  text-align: right;
  font-weight: 600;
  font-size: 16px;
  line-height: 9px;
  letter-spacing: 0;
  color: #434343;
}

.callHistoryRecordsTableWrapper .dataTables_length select {
  border: none;
  background: #FFFFFF;
  color: #713EE6;
  border-bottom: 1px solid #713EE6;
}

.callHistoryRecordWrapper {
  width: 90%;
  margin: -75px 0 75px 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;
  opacity: 1;
  padding: 30px;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  letter-spacing: 0;
  color: #4E4E4E;
}

.callHistoryRecordInstructionText {
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0;
  color: #6C6C6C;
}

.callHistoryRecordInstructionSquare {
  display: inline-block;
  vertical-align: middle;
  height: 15px;
  width: 15px;
  margin-right: 5px;
  margin-top: -1px;
  background: #F0F9ED;
  border: 2px solid #129F43;
}

.callHistoryRecordHeader {
  width: 100%;
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  letter-spacing: 0;
  color: #313131;
  border-bottom: 2px solid #E2E2E2;
}

.callHistoryRecordSubHeader {
  width: 100%;
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0;
  color: #313131;
  border-bottom: 1px solid #E2E2E2;
}

.callHistoryRecordCallFieldLabel {
  width: 100%;
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0;
  color: #313131;
}

.callRecord-changed {
  color: #116E23;
  border-top: 1px solid #ADE5B6 !important;
  border-bottom: 1px solid #ADE5B6 !important;
  background: #EFFCF0;
}