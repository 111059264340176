.specialty-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
  margin-top: -5px;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;

  &__item {
    margin-top: 5px;
    margin-right: 5px;
    padding: 3px 15px 4px;
    background-color: $btn-bg-color;
    font-weight: 600;
    font-size: 14.25px;
    color: #FFFFFF;
    border: none;
    border-radius: 25px;
  }
} // .specialty-list