.no-practitioner-contacts {
  width: 90%;
  min-height: 300px;
  margin: -75px 0 75px 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;
  opacity: 1;
  padding: 15px;
}

.practitionerContactsTableWrapper {
  width: 90%;
  margin: -75px 0 75px 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;
  opacity: 1;
  padding: 30px;
}

.practitionerContactsTableWrapper table thead tr th {
  padding: 10px;
  border-top: none;
  border-bottom: 1px solid #F3F2F4 !important;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #434343;
  align-content: center;
}

.practitionerContactsTableWrapper table thead tr th svg {
  color: #707070;
}

.practitionerContactsTableWrapper table tbody tr td {
  border-top: none !important;
  border-bottom: 1px solid #F3F2F4 !important;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  letter-spacing: 0;
  color: #4E4E4E;
  padding: 10px;
}

.practitionerContactsTableWrapper .dataTables_filter {
  float: none;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 9px;
  letter-spacing: 0;
  color: #434343;
}

.practitionerContactsTableWrapper .dataTables_filter label {
  width: 100%;
}

.practitionerContactsTableWrapper .dataTables_filter input {
  width: 75%;
  padding: 5px 5px;
  background-image: url("../../img/icons/search-solid.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  border: none;
}

.practitionerContactsTableWrapper .dataTables_length {
  float: none;
  text-align: right;
  font-weight: 600;
  font-size: 16px;
  line-height: 9px;
  letter-spacing: 0;
  color: #434343;
}

.practitionerContactsTableWrapper .dataTables_length select {
  border: none;
  background: #FFFFFF;
  color: #713EE6;
  border-bottom: 1px solid #713EE6;
}

.practitionerContactsFormWrapper {
  width: 90%;
  margin: -75px 0 75px 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;
  opacity: 1;
  padding: 30px;
}

.practitionersContactsFormWrapper label,
.practitionersContactsFormWrapper legend {
  font-weight: 600;
  font-size: 17px;
  line-height: 9px;
  color: #4E4E4E;
}