/**
 * CKEditor Styles
 */
.ck-content .table {
  width: auto;
}
.ck-editor__main > .ck-editor__editable {
  min-height: 200px;
}
#master-summary-template_container .ck-content .table,
#master-summary-template_container .ck-content .table table {
  width: 100%;
}
#master-summary-template_container .ck-content .table p {
  margin: 0;
}

#preview-summary_container .ck-content .table,
#preview-summary_container .ck-content .table table {
  width: 100%;
}
#preview-summary_container .ck-content .table p {
  margin: 0;
}