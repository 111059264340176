.btn,
.customPrimaryButton {
  display: inline-block;
  min-width: 113px;
  padding: 7px 20px;
  line-height: 1.4;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.15px;
  color: #FFFFFF;
  background: $btn-bg-color;
  border: none;
  border-radius: 2em;
  text-decoration: none;
  text-align: center;
  transition: background-color .2s ease-in-out;

  &:hover {
    color: #FFFFFF;
    background: $btn-bg-hover;
    text-decoration: none;
  }

  &:focus {
    box-shadow: 0 0 0 0.15rem rgba(208, 195, 245, 0.65);
  }

  .form-row & {
    margin-top: 0.5rem;
  }

  &--secondary {
    background: $btn-secondary-bg-color;

    &:hover {
      background: $btn-secondary-bg-hover;
    }
  }

  &--large {
    min-width: 145px;
    padding: 9px 30px;
    font-size: 16.5px;
  }

  &--ghost {
    padding: 6px 25px;
    background: rgba(255,255,255,.11);
    border: 1px solid rgba(255,255,255,.27);
    color: rgba(255,255,255,.95);
    transition: background-color .2s ease-in-out, border .2s ease-in-out;

    &:hover,
    &:focus {
      background: rgba(255, 255, 255, .2);
      border: 1px solid rgba(255, 255, 255, .37);
      color: #fff;
    }

    &:active {
      background: rgba(255, 255, 255, .25);
      border: 1px solid rgba(255, 255, 255, .5);
      color: #fff;
    }
  }

  &--light {
    background: #E0D5FC;
    color: $link-color;

    &:hover {
      background: #CFC0FA;
      color: $link-color;
    }
  }

  &--light-grey {
    background: #E5E5E5;
    color: #7C7C7C;

    &:hover {
      background: #D8D8D8;
      color: #7C7C7C;
    }
  }
}

.customSecondaryButton {
  @extend .btn;
  background: $btn-secondary-bg-color;

  &:hover {
    background: $btn-secondary-bg-hover;
  }
}

.customDeleteButton {
  @extend .btn;
  background: $btn-delete-bg-color;

  &:hover {
    background: $btn-delete-bg-hover;
  }
}

.customActionForm {
  display: inline-block;
}

.customActionButton {
  display: inline-block;
  height: 30px;
  width: 30px;
  margin: 0 .25rem;
  font-weight: 600;
  font-size: 15px;
  line-height: 35px;
  letter-spacing: 0.15px;
  color: #FFFFFF !important;
  border: none;
  border-radius: 18px;
  text-decoration: none;
  text-align: center;
}

.customActionButton:hover {
  display: inline-block;
  color: #FFFFFF !important;
  text-decoration: none;
}

.customAddAction {
  background: $btn-add-bg-color;

  &:hover {
    background: $btn-add-bg-hover;
  }
}

.customEditAction {
  background: $btn-bg-color;

  &:hover {
    background: $btn-bg-hover;
  }
}

.customDeleteAction {
  background: $btn-delete-bg-color;

  &:hover {
    background: $btn-delete-bg-hover;
  }
}

.customMessageAction,
.customContactsAction,
.customScheduleAction {
  background: $btn-secondary-bg-color;

  &:hover {
    background: $btn-secondary-bg-hover;
  }
}
