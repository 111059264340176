.noSpecialistGroups {
  width: 90%;
  min-height: 300px;
  margin: -75px 0 75px 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;
  opacity: 1;
  padding: 15px;
}

.specialistGroupsTableWrapper {
  width: 90%;
  margin: -75px 0 75px 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;
  opacity: 1;
  padding: 30px;
}


.specialistGroupsTableHeader {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  letter-spacing: 0;
  color: #313131;
}

.specialistGroupFormWrapper {
  width: 90%;
  margin: -75px 0 75px 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;
  opacity: 1;
  padding: 30px;
}

.specialistGroupFormWrapper label,
.specialistGroupFormWrapper legend {
  font-weight: 600;
  font-size: 17px;
  line-height: 9px;
  color: #4E4E4E;
}
