$chat-window-height: 475px;

.chat-form {
  background: #6A1F73;
  color: #FFFFFF;
}

.chat-popup {
  position: fixed;
  height: $chat-window-height;
  width: 350px;
  bottom: 0;
  right: 0;
  z-index: 9;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 25px #00000040;
  border: none;
  border-radius: 7px 7px 0 0;
  overflow: hidden;
  transition: .5s ease-in;

  &__history-link {
    font-size: 12px;
    color: #FFFFFF;
    padding: 0 12px 12px;
    display: block;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
      color: #fff;
    }
  }
} // .chat-popup

.chat-popup.collapsed {
  bottom: calc((-1 * #{$chat-window-height}) + 35px);
}

.chat-popup-name {
  font-weight: 600;
  font-size: 17px;
  line-height: 14px;
  letter-spacing: 0;
  color: #FFFFFF;
  opacity: 1;
  padding: 12px 12px 5px 12px;
  cursor: pointer;
}

.chat-close {
  position: absolute;
  top: 10px;
  right: 12px;
  cursor: pointer;
  transition: .1s ease-in;
}

.chat-close:hover {
  top: 8px;
  font-size: 1.1rem;
}

.chat-popup-messages {
  width: 100%;
  padding: 3px 0;
  margin: 0;
  border: none;
  background: #FFFFFF;
  resize: none;
  height: 285px;
  overflow-y: scroll;
  padding-right: 17px;
  box-sizing: content-box;
}

.inbound-message {
  position: relative;
  float: right;
  width: 245px;
  background: #F0EFF2 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0;
  color: #4E4E4E;
  padding: 15px;
  margin-right: -5px;
  margin-bottom: 10px;
}

.outbound-message {
  position: relative;
  float: left;
  width: 245px;
  background: #0D86DE 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0;
  color: #FFFFFF;
  padding: 15px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.message-image img {
  height: 100px;
  max-height: 100px;
  width: 100px;
  max-width: 100px;
  padding: 5px;
}

.message-image embed {
  height: 100px;
  max-height: 100px;
  width: 100px;
  max-width: 100px;
  padding: 5px;
}

.message-date {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 8px;
  font-weight: 400;
}

.chat-popup-sendMessage {
  width: 100%;
  height: 80px;
  padding: 5px 0 5px 5px;
  margin: 0;
  border: none;
  resize: none;
}

.chat-popup-image-upload {
  padding: 8px 12px;
  cursor: pointer;
  overflow: hidden;
  display: inline-block;
  color: #FFFFFF;

  label {
    cursor: pointer;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }
} // .chat-popup-image-upload

.chat-file-input {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
}

.chat-file-input-filename {
  position: absolute;
  max-height: 25px;
  left: 30px;
  bottom: 5px;
  cursor: default;
}

.smsPractitioner {
  cursor: pointer;
}