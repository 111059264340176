.form {
  &__field-wrapper {
    &--hidden {
      display: none;
    } // &--hidden
  } // &__field-wrapper

  &__controls {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__reset-link {
    margin-left: 15px;
  }
} // .form

/* General */
.customFormHeader {
  width: 100%;
  padding: 5px 0 10px 0;
  margin: 1.25em 0 .75em;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  letter-spacing: 0;
  color: #313131;
  border-bottom: 1px solid #E2E2E2;

  &:first-child {
    margin-top: .25em;
  }
}

label,
.col-form-label {
  font-weight: 600;
  font-size: 17px;
  line-height: 1.4;
  margin-bottom: .25em;
  padding: 0;
}

input[type=text],
input[type=text]:active,
input[type=text]:visited,
input[type=search],
input[type=search]:active,
input[type=search]:visited,
input[type=password],
input[type=password]:active,
input[type=password]:visited,
input[type=time],
input[type=time]:active,
input[type=time]:visited,
input[type=datetime-local],
input[type=datetime-local]:active,
input[type=datetime-local]:visited,
select,
select:active,
select:visited,
textarea,
textarea:active,
textarea:visited {
  &,
  &.form-control {
    background-color: $input-bg-color;
    border-radius: 0 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    letter-spacing: 0 !important;
    color: $input-color;
    border: none !important;
    box-sizing: border-box !important;
    transition: all .2s ease;
  }
}

.form-control:focus,
select:focus,
textarea:focus,
input[type=text]:focus,
input[type=search]:focus,
input[type=password]:focus,
input[type=time]:focus,
input[type=datetime-local]:focus {
  background-color: $input-bg-focus;
  box-shadow: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $input-bg-disabled;
  color: $input-color-disabled;
}

input::placeholder {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0;
  color: #999999;
}

label.form-check-label {
  font-weight: 500;
  font-size: 17px;
  color: $input-color;
}

input[type=checkbox].form-check-input {
  height: 17px;
  width: 17px;
  background: #E2E2E2;
}

input[type=checkbox]:checked {
  color: #FFFFFF;
  background: #7D4BF0;
}

.form-inline {
  .form-control + .form-control {
    margin-left: 5px;
  }
}

/* Inputs */
.customTextInput {
  background: $input-bg-color 0% 0% no-repeat padding-box;
  border-radius: 0;
  font-family: "Slate W01";
  font-weight: 500;
  font-size: 16px;
  color: $input-color;
  padding-left: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.customTextInput::placeholder {
  font-family: "Slate W01";
  font-weight: 500;
  font-size: 16px;
  color: #999999;
}

/* Custom Checkbox and Radio */

.form-check {
  padding-left: 0;
}

.control {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-weight: 500;

  &--input-only {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0 auto;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0;
  }

  &__indicator {
    position: absolute;
    top: 3px;
    left: 0;
    height: 18px;
    width: 18px;
    background: #e6e6e6;
  }

  &--radio {
    .control__indicator {
      border-radius: 50%;
    }
  }

  &:hover input ~ .control__indicator,
  input:focus ~ .control__indicator {
    background: #dadada;
  }

  input:checked ~ .control__indicator {
    background: $btn-bg-color;
  }

  &:hover input:not([disabled]):checked ~ .control__indicator,
  input:checked:focus ~ .control__indicator {
    background: $btn-bg-hover;
  }

  input:disabled ~ .control__indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }

  &__indicator:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .control__indicator:after {
    display: block;
  }

  &--checkbox .control__indicator:after {
    left: 7px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid #fff;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
  }

  &--checkbox input:disabled ~ .control__indicator:after {
    border-color: #7b7b7b;
  }

  /* Radio button inner circle */
  &--radio .control__indicator:after {
    top: 6px;
    left: 6px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fff;
  }

  /* Disabled circle colour */
  &--radio input:disabled ~ .control__indicator:after {
    background: #7b7b7b;
  }
}

/* Select2 Overrides */

.select2-dropdown {
  background: $input-bg-color 0% 0% no-repeat padding-box;
  border: none !important;
  border-radius: 0 !important;
  font-weight: 500;
  font-size: 16px;
  color: $input-color;
  padding-left: 5px;
  border: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.select2-container,
.select2-container--default {

  .select2-selection--single {
    min-height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    background: $input-bg-color 0% 0% no-repeat padding-box;
    border: none !important;
    border-radius: 0 !important;
    font-weight: 500;
    font-size: 16px;
    color: $input-color;
    border: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .select2-selection--multiple {
    background: $input-bg-color;
    border: none !important;
    border-radius: 0 !important;
    font-weight: 500;
    font-size: 16px;
    color: $input-color;
    border: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 38px;
  }

  .select2-container--disabled {
    background: $input-bg-color;
    border: none !important;
    border-radius: 0 !important;
    font-weight: 500;
    font-size: 16px;
    color: $input-color;
    border: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .select2-selection--multiple .select2-selection__rendered {
    display: block;
    padding: 0 10px 5px 10px;
  }

  .select2-selection--single .select2-selection__rendered {
    padding-left: 0;
  }

  .select2-search--inline .select2-search__field {
    margin-top: 7px;
  }

  &.select2-container--open.select2-container--below {
    .select2-selection--single,
    .select2-selection--multiple {
      background: $input-bg-color;
      border: none !important;
      border-radius: 0 !important;
      font-weight: 500;
      font-size: 16px;
      color: $input-color;
      border: none;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  &.select2-container--focus .select2-selection--multiple {
    background: $input-bg-focus;
    border: none !important;
    border-radius: 0 !important;
    font-weight: 500;
    font-size: 16px;
    color: $input-color;
    border: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .select2-selection--multiple .select2-selection__choice {
    background-color: #6A1F73;
    font-weight: 600;
    font-size: 14.25px;
    color: #FFFFFF;
    border: none;
    border-radius: 25px;
    padding: 3px 15px 4px;
  }

  .select2-selection--multiple .select2-selection__choice__remove {
    position: relative;
    text-indent: -99999px;
    padding-right: 10px;
    color: #FFFFFF;

    &:hover {
      color: #E2E2E2;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 11px;
      width: 1px;
      left: 0px;
      top: 50%;
      margin-top: -4px;
      background: #fff;
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  .select2-selection--single .select2-selection__arrow {
    height: 38px;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 35px;
  }

  .select2-results__group,
  .select2-results__option {
    padding: 5px 12px;
  }

  .select2-results__option--highlighted[aria-selected] {
    background: #E3E3E3;
    color: $text-color;
  }

  .select2-results__option[aria-selected=true] {
    background: $btn-bg-color;
    color: #fff;

    &:hover {
      background: $btn-bg-hover;
    }
  }
}

.select2-container--open .select2-dropdown--below {
  padding: 0;
}
