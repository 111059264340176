.dashboard {
  top: 0;
  right: 0;
  position: static;
  width: 100%;
  min-width: 1540px;
  padding-left: 250px;
  height: 100%;
  min-height: 100vh;
  background: #F3F2F4 0% 0% no-repeat padding-box;
  opacity: 1;
  z-index: 1;
}

.contentWrapper {
  width: auto;
  max-width: 1450px;
  margin: -55px 50px 75px 50px;
  padding: 35px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;

  & + & {
    margin: -10px 0 75px 50px;
  }
}

.topBar {
  width: 100%;
  height: 209px;
  background: transparent linear-gradient(90deg, #6A1F73 0%, #6A1F73 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.topBarInner {
  width: 100%;
  height: 209px;
  background-image: url("../../img/graphics/banner-graphic.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}

.topBarInner .row {
  height: 125px;
  max-width: 1550px;
  padding: 50px 70px 0 50px;
}

.topBarInner .dashboardHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-weight: 300;
  font-size: 37px;
  line-height: 1.2;
  letter-spacing: 0;
  color: #FFFFFF;
  opacity: 1;
}

.dashboardHeader .btn {
  margin-left: 1.75em;
  margin-top: 3px;
}

.topBar .dropdown-bubble .toggle {
  padding-right: 15px;
  margin-right: -15px;
}

.topBarUser .dropdown-bubble .dropdown-menu {
  margin-top: 20px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 25px #00000038;
  border-radius: 7px;
  border: none;
  opacity: 1;
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  letter-spacing: 0;
  padding: 0;

  li:first-of-type a {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }

  li:last-of-type a {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  a {
    display: block;
    padding: 5px 15px;
    color: #4E4E4E;
    text-decoration: none;
    font-weight: 500;

    &:active,
    &:focus,
    &:hover {
      background: #F0EFF2;
    }
  }
}

.topBarUser .dropdown-bubble .dropdown-menu:before,
.topBarUser .dropdown-bubble .dropdown-menu:after{
  content: ' ';
  display: block;
  border-style: solid;
  border-width: 0 .75em 1em .75em;
  border-color: transparent;
  position: absolute;
  right: .6em;
}

.topBarUser .dropdown-bubble .dropdown-menu:after {
  top: -.4em;
  border-bottom-color: #fff;
  z-index: -1;
}

.topBar .notificationIcon ion-icon {
  width: 28px;
}

.topBarUser {
  display: inline-block;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0;
  color: #FFFFFF;
  opacity: 1;
  cursor: pointer;
}

.topBarUser .userIcon {
  position: relative;
  top: 10px;
  left: 0px;
  width: 28px;
}

.topBarUser .dropdownArrowIcon {
  position: relative;
  top: 5px;
  left: 0px;
}

.notifications {
  display: inline-block;
}

.topBar .notificationIcon {
  position: relative;
  top: 10px;
  left: 0px;
  cursor: pointer;
}

.notificationCount {
  position: absolute;
  top: -2px;
  right: -7px;
  color: #FFFFFF;
  background-color: #F47314;
  border: 2px solid #5f38c5;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 11px;
  line-height: 1.4;
  text-align: center;
  cursor: pointer;
}

