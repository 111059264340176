.loginContainer {
  positon: fixed;
  height: 100vh;
  width: 100%;
  background: #F3F2F4 0% 0% no-repeat padding-box;
}

.loginForm {
  width: 25%;
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 15px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #00000017;
  border-radius: 7px;
}

.loginLogo{
  padding: 15px;
}