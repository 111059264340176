.widgetWrapper {
  //width: 89%;
  width: auto;
  max-width: 1450px;
  margin-top: -55px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 100px;

  > .row {
    margin-left: -25px;
    margin-right: -25px;
  }

  .widgetColumn {
    padding: 0 25px;
  }
}

.widgetWrapper .widget {
  width: 100%;
  margin: 0 0 50px 0;
  border-radius: 7px 8px 8px 7px !important;
  box-shadow: 0px 1px 20px #0000001D;

  .card-title {
    font-size: 21px;
    margin-bottom: 1em;

    ion-icon {
      vertical-align: middle;
      margin-top: -3px;
      margin-right: 2px;
      font-size: 23px;
    }

    .svg-inline--fa {
      margin-top: 2px;
      margin-right: 3px;
    }
  }

  table thead tr th {
    padding: 5.5px 5.5px 6px;
    border-top: none;
    border-bottom: 1px solid #F3F2F4 !important;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #434343;
    align-content: center;
    white-space: wrap;
  }

  table tbody tr td {
    padding: 5.5px;
    height: 30px;
    vertical-align: middle;
    border-top: none !important;
    border-bottom: 1px solid #F3F2F4 !important;
    font-weight: 500;
    font-size: 16.5px;
    letter-spacing: 0;
    color: #4E4E4E;
  }
}

.widgetFooter {
  margin-top: 30px;
}

.widgetInstructionText {
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0;
  color: #6C6C6C;
}

.widgetInstructionSquare {
  display: inline-block;
  vertical-align: middle;
  height: 15px;
  width: 15px;
  margin-right: 5px;
  margin-top: -1px;
  background: #FFF3F0;
  border: 2px solid #DB230B;
}

.pendingCallsWidget {
  border-left: solid 7px #F47314;
}

.pendingCallsWidget table {
  border: none !important;
}

.pendingCallsWidget table tbody tr:hover {
  background: #FFFFFF;
}

.pendingCallsWidget .call-outOfWindow td {
  color: #C3210C;
  border-top: 1px solid #FCA79D !important;
  border-bottom: 1px solid #FCA79D !important;
  background: #FFF3F0;
}

.pendingCallsWidget .call-outOfWindow:hover {
  border-top: 1px solid #FCA79D;
  border-bottom: 1px solid #FCA79D;
  background: #FFF3F0;
}

.pendingCallsWidget .widgetInstructionSquare {
  background: #FFF3F0;
  border: 2px solid #DB230B;
}

.pendingSummaryWidget {
  border-left: solid 7px #F79B1D;
}

.pendingSummaryWidget table {
  border: none !important;
}

.pendingSummaryWidget table tbody tr:hover {
  background: #FFFFFF;
}

.pendingSummaryWidget .call-summaryGenerated td {
  color: #116E23;
  border-top: 1px solid #ADE5B6 !important;
  border-bottom: 1px solid #ADE5B6 !important;
  background: #EFFCF0;
}

.pendingSummaryWidget .call-summaryGenerated:hover {
  border-top: 1px solid #ADE5B6;
  border-bottom: 1px solid #ADE5B6;
  background: #EFFCF0;
}

.pendingSummaryWidget .widgetInstructionSquare {
  background: #F0F9ED;
  border: 2px solid #129F43;
}

.completedCallsWidget {
  border-left: solid 7px #1BA059;
}

.completedCallsWidget table {
  border: none !important;
}

.completedCallsWidget table tbody tr:hover {
  background: #FFFFFF;
}

.completedCallsWidget .call-unsuccessful td {
  color: #C3210C;
  border-top: 1px solid #FCA79D !important;
  border-bottom: 1px solid #FCA79D !important;
  background: #FFF3F0;
}

.completedCallsWidget .call-unsuccessful:hover {
  border-top: 1px solid #FCA79D;
  border-bottom: 1px solid #FCA79D;
  background: #FFF3F0;
}

.practitionersWidget {
  border-left: solid 7px #18A0A5 !important;
}

.practitionersWidget input[type=text],
.practitionersWidget input[type=text]:focus {
  width: 95%;
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-radius: 0;
  font-family: "Slate W01";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  color: #5A5A5A;
  padding-left: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.practitionersWidget input::placeholder {
  font-family: "Slate W01";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  color: #999999;
}

.practitionersWidget select,
.practitionersWidget select:focus {
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-radius: 0;
  font-family: "Slate W01";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  color: #5A5A5A;
  padding-left: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.practitionersWidget .js-practitioner-list {
  margin-bottom: 2.25em;
}

.practitionersWidget table {
  border: none !important;
}

.practitionersWidget table tbody tr:hover {
  background: #FFFFFF;
}

.unavailableSpecialistWidget {
  border-left: solid 7px #DB230B;
}

.unavailableSpecialistWidget label {
  font: Medium 17px/9px Slate;
  font-weight: 600;
  font-size: 17px;
  line-height: 9px;
  letter-spacing: 0;
  color: #4E4E4E;
}

.unavailableSpecialistWidget input[type=text],
.unavailableSpecialistWidget input[type=text]:focus {
  width: 95%;
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-radius: 0;
  font-family: "Slate W01";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  color: #5A5A5A;
  padding-left: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.unavailableSpecialistWidget input::placeholder {
  font-family: "Slate W01";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  color: #999999;
}

.unavailableSpecialistWidget select,
.unavailableSpecialistWidget select:focus {
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-radius: 0;
  font-family: "Slate W01";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  color: #5A5A5A;
  padding-left: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.unavailableSpecialistWidget table {
  border: none !important;
}

.unavailableSpecialistWidget table tbody tr:hover {
  background: #FFFFFF;
}

.widget {
  $block: &;

  &--practitioners {
    #{$block}__loader {
      display: none;
    } // #{$block}__loader
  } // &--practitioners
} // .widget
